"use strict";

// Class Definition
var KTLogin = function() {
    var _login;

    var _showForm = function(form) {
        var cls = 'login-' + form + '-on';
        var form = 'kt_login_' + form + '_form';

        _login.removeClass('login-forgot-on');
        _login.removeClass('login-signin-on');
        _login.removeClass('login-signup-on');

        _login.addClass(cls);

        KTUtil.animateClass(KTUtil.getById(form), 'animate__animated animate__backInUp');
    }

    var _handleSignInForm = function() {

        // Handle forgot button
        $('#kt_login_forgot').on('click', function (e) {
            e.preventDefault();
            _showForm('forgot');
        })

        // Handle signup
        $('#kt_login_signup').on('click', function (e) {
            e.preventDefault();
            _showForm('signup');
        });


    }

    var _handleSignUpForm = function(e) {
        // Handle cancel button
        $('#kt_login_signup_cancel').on('click', function (e) {
            e.preventDefault();
            _showForm('signin');
        });
    }

    var _handleForgotForm = function(e) {

        // Handle cancel button
        $('#kt_login_forgot_cancel').on('click', function (e) {
            e.preventDefault();

            _showForm('signin');
        });
    }


    var _handleSignGoogle = function(e) {

        // Handle submit button
        $('#signInWithGoogle').on('click', function (e) {
            e.preventDefault();
            KTToast.alert({
                showConfirmButton: false,
                text: "The request is being processed...",
            }, "info");
            $("#googleLogin").submit();
        });

    }

    // Public Functions
    return {
        // public functions
        init: function() {
            _login = $('#kt_login');

            _handleSignInForm();
            _handleSignUpForm();
            _handleForgotForm();
			_handleSignGoogle();

            if($('#kt_login_signin_form').hasClass("accountConfirmed")) {
                KTToast.alert({html:$.t("modal.accountConfirmed")}, "success");
            }
            if($('#kt_login_signin_form').hasClass("restartPasswordSuccess")) {
                KTToast.alert({html:$.t("modal.restartPasswordSuccess")}, "success");
            }

            if(typeof ERRORS_LOGIN !== 'undefined') {
                this.errors(ERRORS_LOGIN);
            }

            if(window.location.hash == "#kt_login_signup_form") {
                _showForm('signup');
            }

        },
        errors: function (data) {
            KTToast.alert({
                html:data,
            }, "error");
        }
    };
}();

// Class Initialization
document.addEventListener('afterJSTranslateLoad', function () {
	KTLogin.init();
});
